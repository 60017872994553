<template>
  <div ref="infocookies" class="infocookies">
    <div class="container">
      <div class="flex justify-between py-3">
        <p>
          {{ $t('common.info_cookies.title') }}
        </p>
        <Btn
          class="px-4"
          type="button"
          color="white"
          @click.prevent="acceptCookies"
        >
          {{ $t('common.ok') }}
        </Btn>
      </div>
      <p>
        {{ $t('common.info_cookies.description') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from '~/store/app'

const appStore = useAppStore()
const nuxtApp = useNuxtApp()

const infocookies = ref(null)

onMounted(() => {
  // this.$refs.infocookies.classList.add('ask')
  setTimeout(() => {
    const acceptedCookies = nuxtApp.$cookies.get('acceptedCookies')
    if (!acceptedCookies) {
      try {
        infocookies.value.classList.add('ask')
      } catch (e) {}
    }
  }, 3000)
})

function acceptCookies() {
  try {
    appStore.setAcceptedCookies()
    infocookies.value.classList.remove('ask')
  } catch (e) {}
}
</script>

<style lang="scss">
.infocookies {
  background: black;
  color: white;
  position: fixed;
  bottom: 0;
  max-height: 0px;
  width: 100%;
  transition: 0.5s ease-in-out max-height;
  z-index: 99;
  overflow: hidden;

  &.ask {
    max-height: 500px;
  }
}
</style>
